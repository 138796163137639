.dupzNu {
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.zXDlL {
  margin-bottom: 15px;
  width: 80%;
}

.kLRygp {
  font-size: 20px;
}

.jiaskw {
  color: rgb(131, 146, 165);
  margin-bottom: 40px;
}

.kniyBm {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.fgGdWf {
  display: block;
  width: 100%;
  height: 38px;
  padding: 0.46875rem 0.625rem;
  font-size: 0.875rem;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(27, 46, 75);
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 1px solid rgb(192, 204, 218);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.iiGwAM {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  user-select: none;
  border: 1px solid rgb(4, 40, 147);
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  color: rgb(255, 255, 255);
  background-color: rgb(4, 40, 147);
  padding: 0.46875rem 0.9375rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  margin-top: 10px;
  margin-left: 0px;
}

@media (min-width: 576px) {
  .dupzNu {
    width: 450px;
  }
  .zXDlL {
    width: 300px;
  }
  .kLRygp {
    font-size: 24px;
  }
  .kniyBm {
    flex-direction: row;
  }
  .fgGdWf {
    width: 250px;
  }
  .iiGwAM {
    margin-top: 0px;
    margin-left: 10px;
  }
}
