.MuiTableCell-root.element-visible {
  color: red;

  .warning-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.2em;
  }
}

.MuiTableCell-root.element-hidden {
  .warning-icon {
    display: none;
  }
}
