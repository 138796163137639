.haFYRS {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  align-items: stretch;
  height: 55px;
  padding: 0px;
  -webkit-box-pack: start;
  justify-content: flex-start;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
}
.ARXcN {
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 15px;
  margin-right: 0px;
  order: 1;
}

.kiQvRe {
  font-weight: 700;
  font-size: 22px;
  letter-spacing: -1px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  color: rgb(3, 26, 97);
  margin-top: -4px;
}

.kiQvRe span {
  display: inline-block;
  font-weight: 400;
  color: rgb(1, 104, 250);
}

/* BODY */
.dHbgqB {
  padding: 20px;
  min-height: calc(100vh - 107px);
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.jtrHBG {
  padding-left: 0px;
  padding-right: 0px;
}

.dDRJpc {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.chigLQ {
  position: relative;
  height: 100%;
  -webkit-box-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  justify-content: center;
}

.fPTPwa {
  display: flex;
  align-items: flex-start;
}

.tBulW {
  -webkit-box-align: center;
  align-items: center;
  display: none;
}

.mobile-img {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
}

.eSRnOk {
  flex: 1 1 0%;
}

.dxUnis {
  width: 600px;
}

img {
  max-width: 100%;
}

img {
  border: 0px;
  vertical-align: middle;
}

.cewXZL {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 340px;
}

.gtIlHT {
  width: 100%;
}

.eRlVrJ {
  color: rgb(0, 23, 55);
  margin-bottom: 5px;
}

.ddCqhf {
  font-size: 16px;
  color: rgb(131, 146, 165);
  margin-bottom: 40px;
}

p {
  margin: 0px 0px 15px;
  font-family: 'IBM Plex Sans', sans-serif;
  color: rgb(0, 23, 55);
}

/* FORM */
.vrcaG {
  margin-bottom: 20px;
}

.cHOCRe {
  display: inline-block;
}
.kGMEvx {
  display: block;
  width: 100%;
  height: 38px;
  padding: 0.46875rem 0.625rem;
  font-size: 0.875rem;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(27, 46, 75);
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 1px solid rgb(192, 204, 218);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.cnjEWl {
  margin-bottom: 5px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  display: flex;
}

.kscDRi {
  font-size: 13px;
}

a {
  transition: all 0.4s ease 0s;
  color: rgb(1, 104, 250);
  text-decoration: none;
}

.iaBmoU {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  user-select: none;
  border: 1px solid rgb(4, 40, 147);
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  color: rgb(255, 255, 255);
  background-color: rgb(4, 40, 147);
  padding: 0.46875rem 0.9375rem;
  font-size: 0.875rem;
  display: block;
  width: 100%;
  border-radius: 0.25rem;
}

.cnjEWl {
  margin-bottom: 5px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  display: flex;
}

.dmqboF {
  font-size: 12px;
  text-align: center;
  left: 0px;
  bottom: -10px;
  position: absolute;
}

.error {
  color: red;
  padding: 10px 0;
  text-align: center;
}

/* FOOTER */
.igAodF {
  font-size: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', Roboto, sans-serif;
  letter-spacing: 0.3px;
  padding: 15px 25px;
  background-color: rgb(250, 251, 252);
  color: rgb(99, 115, 136);
  border-top: 1px solid rgba(72, 94, 144, 0.16);
  text-transform: uppercase;

  .logo-m {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
}

@media (min-width: 576px) {
  .dDRJpc {
    max-width: 540px;
  }
  .kiQvRe {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .dDRJpc {
    max-width: 720px;
  }
  .eeTpIf {
    margin-top: 0px;
  }
}

@media (min-width: 992px) {
  .haFYRS {
    height: 60px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1000;
  }
  .kiQvRe {
    font-size: 24px;
  }
  .ARXcN {
    width: 240px;
    padding: 0px 0px 0px 20px;
  }
  .dHbgqB {
    padding: 25px;
    margin-top: 60px;
  }
  .dDRJpc {
    max-width: 960px;
  }
  .tBulW {
    display: flex;
  }
  .mobile-img {
    display: none;
  }
  .cewXZL {
    margin-left: 50px;
  }
  h3,
  .h3 {
    font-size: 1.53125rem;
  }
  .igAodF {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

@media (min-width: 1200px) {
  .dHbgqB {
    padding: 30px;
  }
  .dDRJpc {
    max-width: 1140px;
  }

  .cewXZL {
    margin-left: 60px;
    width: 340px;
  }
}
