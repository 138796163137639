.home {
    display: flex;
    width: 100%;

    .homeContainer {
        // background-color: lightblue;
        flex: 6;
    }
    // background-color: rebeccapurple;
    // .title {
    //     color: white;
    // }
}