nav {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 15%;
  min-height: 100vh;
  background-color: lightgray;
  padding: 2rem 1rem 1rem 1rem;
  transition: transform 1s;
}

.navbar {
  transform: translateX(-100%);
}

.nav-btn {
  position: absolute;
  transform: translateX(38px);
  top: 20px;
  right: 0;
  width: 40px;
  height: 60px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #753ffd;
  background-color: lightgray;
  outline: none;
  border: none;
  font-size: 1rem;
  padding: 5px 10px;
  cursor: pointer;
  color: #fff;
  z-index: 99;
}

.logo {
  display: block;
  width: 130px;
  margin: auto;
  background: transparent;
  color: rgb(3, 26, 97);
  font-size: 20px;
  font-weight: 700;
}

.logo span {
  font-weight: 400;
  color: rgb(1, 104, 250);
}

.logo img {
  display: block;
  width: 100%;
  height: 100%;
}

.nav-link {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  padding: 10px 1rem;
  border-radius: 5px;
  margin-bottom: 5px;
}

.nav-link:hover {
  background-color: #afb4ff;
  color: #4e4e4e;
}

.nav-link span {
  margin-left: 10px;
}

.nav-top {
  margin-top: 10rem;
}

.mobile-nav {
  background-color: lightgray;
  width: 100%;
  height: 40px;
  display: none;
  padding: 10px 0;
}

.mobile-nav-btn {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  margin: 0 10px;
}

@media only screen and (max-width: 768px) {
  .navigation {
    width: 40%;
    padding: 10px;
    z-index: 999;
  }
}

@media only screen and (max-width: 450px) {
  .mobile-nav {
    display: flex;
    align-items: center;
  }
  nav {
    width: 70%;
    top: 38px;
    min-height: calc(100vh - 38px);
  }

  .nav-btn {
    display: none;
  }
}
