.MuiGrid-root li.MuiListItem-root {
  padding: 0 10px;

  .level2 {
    padding-left: 30px;
  }
  .level3 {
    padding-left: 60px;
  }
  .level4 {
    padding-left: 90px;
  }
  .level5 {
    padding-left: 120px;
  }
}
