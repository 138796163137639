* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.page {
  flex-basis: 100%;
  // display: flex;
  justify-content: center;
  align-items: center;
  transition: margin-left 1s;
  overflow-x: hidden;
  overflow-y: auto;
}

.page-with-navbar {
  margin-left: 15%;
}

// Header
.header {
  // position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  height: 55px;
  padding: 0px;
  justify-content: flex-start;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  padding: 0 40px;

  .header-nav-wrap {
    display: flex;
    width: 100%;

    .current-title {
      padding: 0px 15px;
      // margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 1rem;
      color: rgb(1, 104, 250);
    }

    .device-info {
      // flex-shrink: 0; /* shrinks to 0 to apply 70% width*/
      flex-basis: 70%; /* sets initial width to 70% */
      display: flex;
      justify-content: center;
      align-items: center;

      .manufacturer,
      .model {
        font-weight: 700;
      }
    }

    .user-info {
      padding: 0px 15px;
      margin-left: auto;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: end;
      justify-content: flex-end;
      order: 2;

      .ava {
        font-size: 13px;

        .username {
          color: gray;
          font-size: 12px;
        }
      }
    }

    .box {
      // background-color: rgb(245, 215, 160);
      // padding: 10px;
      // border-radius: 12px;
      // border: 2px solid rgb(116, 113, 113);
      // margin: 1rem;
      // box-shadow: 1px 1px 1px #000;
    }
  }
}

// Body
.container-wrapper {
  padding: 25px 30px;
}

// Footer
.footer {
  font-size: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter UI', Roboto, sans-serif;
  letter-spacing: 0.3px;
  padding: 15px 25px;
  background-color: rgb(250, 251, 252);
  color: rgb(99, 115, 136);
  border-top: 1px solid rgba(72, 94, 144, 0.16);
  text-transform: uppercase;

  .logo-m {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .page-with-navbar {
    width: 100%;
    margin-left: 0;
  }

  .MuiGrid-root.MuiGrid-item {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-basis: 100%;
    padding-right: 0;
    padding-left: 0 !important;

    .MuiPaper-root {
      width: 100%;
    }
  }

  .MuiGrid-root.MuiGrid-item.params_device_table {
    display: block;
  }
}

@media (min-width: 992px) {
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

@media only screen and (max-width: 900px) {
  .header-nav-wrap {
    /* it place the items in vertical direction */
    flex-direction: column;
  }

  .box {
    margin: 0 0 1rem;
  }
}

@media only screen and (max-width: 905px) {
  .header .header-nav-wrap .user-info {
    display: none;
  }
}
