.kMPuRP {
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.ccVxvL {
  margin-bottom: 15px;
  width: 70%;
}
.isTfLR {
  font-size: 24px;
}
.iKHUqQ {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}
.jnIkmC {
  color: rgb(131, 146, 165);
  margin-bottom: 30px;
}
.MUbdA {
  margin-bottom: 40px;
  display: flex;
}
.iLQNEh {
  display: block;
  height: 38px;
  padding: 0.46875rem 0.625rem;
  font-size: 0.875rem;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(27, 46, 75);
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 1px solid rgb(192, 204, 218);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 200px;
}

.iSblIP {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  user-select: none;
  border: 1px solid rgb(4, 40, 147);
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  color: rgb(255, 255, 255);
  background-color: rgb(4, 40, 147);
  padding: 0.46875rem 0.9375rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  margin-left: 5px;
}
.iUcYJY {
  color: rgb(131, 146, 165);
  font-size: 12px;
}

@media (min-width: 576px) {
  .ccVxvL {
    width: 250px;
  }
  .isTfLR {
    font-size: 32px;
  }
  .iKHUqQ {
    font-size: 18px;
  }
  .iLQNEh {
    width: 250px;
  }
  .iSblIP {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 992px) {
  .ccVxvL {
    width: 300px;
  }
  .isTfLR {
    font-size: 36px;
  }
  .iKHUqQ {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .isTfLR {
    margin-bottom: 5px;
  }
}
